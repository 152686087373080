import { PageNotBuilt, PageLayout } from "@components";

import "@styles/Page.scss";

export default function FeatAuditTrail() {
  return (
    <PageLayout title="Audit trail">
      <PageNotBuilt />
    </PageLayout>
  );
}
